import React from 'react'
import './index.css'
import MoreFeatureCard from '../MoreFeatureCard'
import FakePasswordPic from '../../assets/images/fake-password.png'
import TapToSwitchPic from '../../assets/images/tap-to-switch.png'
import DownloadPic from '../../assets/images/download.png'
import IntrudersPic from '../../assets/images/intruders.png'
import NotePic from '../../assets/images/note.png'
import ProgressDots from '../ProgressDots'

export default (props) => {

  return (
    <div className='feature-section-outer'>
      <ProgressDots currentPosNumber={4} total={7} isForColored={false} />
      <div className='features-section'>
        <div className='title'>Exploring more features</div>
        <div className='feature-line'>
          <MoreFeatureCard picture={FakePasswordPic} title={'Fake password'} altText={'fake password'}
            description={'When the fake PIN is entered, an innocent Noseeyou will open without any private data.'} />
          <MoreFeatureCard picture={TapToSwitchPic} title={'Turn to switch'} altText={'turn to switch'}
            description={'Design for emergency situations. Automatic switch to a Safari page when you turn the phone over.'} />
          <MoreFeatureCard picture={DownloadPic} title={'Download'} altText={'download'}
            description={'Download website images simply with one tap.'} />
          <MoreFeatureCard picture={IntrudersPic} title={'Intruders'} altText={'intruders'}
            description={'If anyone breaks into Noseeyou with an incorrect PIN, a screenshot will be taken for review.'} />
          <MoreFeatureCard picture={NotePic} title={'Note'} altText={'note'}
            description={'We offer both text and voice memo features for your personal notes.'} />
        </div>
      </div>
    </div>
  )
}
