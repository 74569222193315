import React from "react";
import { Link } from "react-router-dom";
import 'bulma/css/bulma.css';
import './Navigation.css';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileMenuHidden: true,
      navbarToggleClass: ''
    };

    this.handleBugerClickOfMobileMode = this.handleBugerClickOfMobileMode.bind(this);
  }

  handleBugerClickOfMobileMode() {
    if (this.state.isMobileMenuHidden) {
      this.setState((prevState) => {
        return {
          isMobileMenuHidden: false,
          navbarToggleClass: 'navbar-showing'
        };
      })
    } else {
      this.setState((prevState) => {
        return {
          isMobileMenuHidden: true,
          navbarToggleClass: ''
        };
      })
    }
  }

  render() {
    let navbarClassName = "navbar";
    let siteTitleClassName = "site-title";
    let navbarMenuClassName = this.state.navbarToggleClass + "navbar-menu";
    let navbarEndClassName = "navbar-end";
    let navbarItemClassname = "navbar-item";
    let { theme } = this.props;

    if (theme === "dark") {
      navbarClassName += " navbar-dark";
      siteTitleClassName += " site-title-dark";
      navbarMenuClassName += " navbar-menu-dark";
      navbarItemClassname += " navbar-item-dark";
    }

    return (
      <nav className={navbarClassName} aria-label="main navigation">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <div className={siteTitleClassName}>Noseeyou</div>
          </Link>

          <button onClick={this.handleBugerClickOfMobileMode} className={`navbar-burger ${theme}`} aria-label="menu" aria-expanded="false">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>
        <div className={navbarMenuClassName}>
          <div className={navbarEndClassName}>
            <Link className={navbarItemClassname} to="/">
              Home
            </Link>
            <Link className={navbarItemClassname} to="/privacy">
              Terms
            </Link>
            <Link className={navbarItemClassname} to="/about">
              About
            </Link>
          </div>
        </div>
      </nav>
    )
  }
}

export default NavBar;
