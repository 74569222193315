import React from 'react';
import './index.css';

export default (props) => {

  const buildItems = () => {
    return props.plainText ?
      <div className='plain-text'>{props.plainText}</div> :
      props.items.map((item, index) => {
        return (
          <div className='item' key={index}>
            <img src={item.imageSrc} alt={item.alt} className='icon' />
            <div className='comment'>{item.comment}</div>
          </div>
        )
      })
  }

  return (
    <div className={ `texts-container ${props.isActive ? ' fadeInUp' : ' disappear'}` }>
      <div className='feature-point'>{props.featurePoint}</div>
      <div className={`highlight${props.highlightColor ? ` ${props.highlightColor}` : ''}`}>{props.highlight}</div>
      <div className='description'>
        <div className='items'>
          {
            buildItems()
          }
        </div>
      </div>
    </div>
  )
}
