import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import FacebookIcon from "../../assets/images/facebook_icon.png";
import TwitterIcon from "../../assets/images/twitter_icon.png";

class Footer extends React.Component {
  render() {
    return (
      <section className="section footer-section">
        <div className="container">
        <div className="level footer-level-top">
          <div className="level-left">
            <div className="level-item footer-level-item footer-level-item-logo">
              <div className="footer-copyright">
                © 2019<br/>
                noseeyou.com
              </div>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item footer-level-item" style={{display: "none"}}>
              <Link to="#"><img className="social-link-icon" src={TwitterIcon} alt="twitter icon"/></Link>
              <Link to="#"><img className="social-link-icon" src={FacebookIcon} alt="facebook icon"/></Link>
            </div>
          </div>
        </div>
        <div className="level">
          <div className="level-left">
          </div>
          <div className="level-right">
            <div className="level-item footer-level-item">
              <Link className="footer-inside-link" to="/privacy">Terms of Service</Link>
            </div>
            <div className="horizontal-divider"></div>
            <div className="level-item footer-level-item">
              <Link className="footer-inside-link" to="/privacy">Privacy Policy</Link>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Footer;
