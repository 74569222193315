import React, { useState, useEffect } from 'react';
import './index.css';
import UserComment from '../UserComment';
import { xml2js } from 'xml-js';
import axios from 'axios';
import ProgressDots from '../ProgressDots';

export default (props) => {
  let [ comments, setComments ] = useState([]);
  let [ activeComment, setActiveComment ] = useState(null)
  let [ activeIndex, setActiveIndex ] = useState(0);
  let [ isCommentsFetched, setIsCommentsFetched ] = useState(false);

  useEffect(() => {
    axios.get('https://itunes.apple.com/us/rss/customerreviews/id=1186829987/sortBy=mostRecent/xml')
      .then(function (response) {
        // handle success
        let parsedResponseData = xml2js(response.data, {compact: true, spaces: 4});
        let fiveStarComments = parsedResponseData.feed.entry.filter((c) => {
          return c['im:rating']._text === '5'
        })
        let parsedComments = fiveStarComments.map((c) => {
          return {
            title: c.title._text,
            rating: c['im:rating']._text,
            content: c.content.filter(con => con._attributes.type === 'text')[0]._text,
            author: c.author.name._text,
            updated_at: c.updated._text
          }
        })

        setComments(parsedComments);
        setActiveComment(parsedComments[0])
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
        setIsCommentsFetched(true)
      });
  }, [isCommentsFetched])

  const handleAnotherComment = (direction) => {
    return (currentIndex) => {
      switch (direction) {
        case 'NEXT':
          currentIndex += 1;
          break;
        case 'PREV':
          currentIndex -= 1;
          break
        default:
          return;
      }
      if (currentIndex < 0) {
        currentIndex = comments.length - 1;
      } else if (currentIndex >= comments.length) {
        currentIndex = 0;
      }
      setActiveIndex(currentIndex);
      setActiveComment(comments[currentIndex])
    }
  }

  return (
    <div className='uc-outer'>
      <ProgressDots currentPosNumber={6} total={7} isForColored={false} />
      <div className='uc-container'>
        <div className='uc-inner-container'>
          <div className='uc-description'>
            Trusted by more than 7.5 million users.
          </div>
          <div className='comments'>
            <UserComment
              data={activeComment}
              activeIndex={activeIndex}
              handleNext={ handleAnotherComment('NEXT') }
              handlePrev={ handleAnotherComment('PREV') }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
