import React from 'react';
import './index.css';

export default (props) => {
  return (
    <div className='card'>
      <div className='picture'>
        <img className={props.imageClassName} src={props.picture} alt={props.altText} />
      </div>
      <div className='title'>{props.title}</div>
      <div className='description'>{props.description}</div>
    </div>
  )
}
