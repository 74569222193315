import React from 'react';
import './index.css';
import ProgressDots from '../ProgressDots';

export default (props) => {
  return (
    <div className="content-outer">
      <ProgressDots currentPosNumber={props.sectionIndex} total={7} isForColored={props.isForColoredProgress} />
      <div className={`section content-container ${props.isReversed ? 'direction-reverse' : 'direction-normal'}${props.className ? ` ${props.className}` : ''}`}>
        <div className={`text-part${props.isReversed ? ' reversed' : ''}`}>
          {props.textPart}
        </div>
        <div className='image-part'>
          {props.imagePart}
        </div>
      </div>
    </div>
  )
}
