import React from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';

class DownloadButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    let { className, storeName } = this.props;

    return (
      <a className={`download-button ${this.props.isMini ? 'mini-size' : ''} ${className ? `${className} ` : ''}`} onClick={this.props.onClick} href={this.props.downloadLink}>
        { storeName === 'APPLE' ? <FontAwesomeIcon className='brand-icon' icon={faApple} /> : null}
        { storeName === 'GOOGLE' ? <img src={require('../../assets/images/google_play_icon.png')} alt='google play icon' className='brand-icon' /> : null}
        <div>
          <div className="small-text">
            { storeName === 'APPLE' ? 'Download on the' : null }
            { storeName === 'GOOGLE' ? 'GET IT ON' : null }
          </div>
          <div className="larger-text">
            { storeName === 'APPLE' ? 'App Store' : null }
            { storeName === 'GOOGLE' ? 'Google Play' : null }
          </div>
        </div>
      </a>
    );
  }
}

export default DownloadButton;
