import React from 'react'
import './index.css'
import ProgressDots from '../ProgressDots'

export default (props) => {

  return (
    <div className='ds-outer'>
      <ProgressDots currentPosNumber={5} total={7} isForColored={true} />
      <div className='ds-bg-container'>
        <div className='circle small'></div>
        <div className='circle big'></div>
      </div>
      <div className='ds-infos'>
        <div className='contents'>
          <div className='ds-title'>Your data security is our main concern.</div>
          <div className='ds-description'>We aim to provide a secure digital environment and a completely private space for all users.</div>
        </div>
        <div className='image-container'>
          <img src={require('../../assets/images/lock-with-logo.png')} alt='awesome lock' />
        </div>
      </div>
    </div>
  )
}
