import React from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as faCircleSolid } from '@fortawesome/free-solid-svg-icons';

export default (props) => {

  const buildDots = (options) => {
    let circles = []
    while (circles.length < options.total) {
      if (circles.length === options.currentPosNumber) {
        circles.push(
          <FontAwesomeIcon
            key={ circles.length }
            icon={ faCircleSolid }
            color={options.isForColored ? '#fff' : '#000'}
            className={options.isForColored ? 'solid-circle current colored' : 'solid-circle current normal'}
            size='sm'
          />
        )
      } else {
        circles.push(
          <FontAwesomeIcon
            key={ circles.length }
            icon={ faCircleSolid }
            color={options.isForColored ? '#fff' : '#000'}
            className={options.isForColored ? 'solid-circle others colored' : 'solid-circle others normal'}
          />
        )
      }
    }
    return circles;
  }
  return (
    <div className='progress-dots-container'>
      { buildDots(props) }
    </div>
  )
}
