import React, { useState, useRef } from 'react'
import './index.css'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Navigator from '../../components/Navigator'
import HomeBanner from '../../components/HomeBanner'
import MainContent from '../../components/MainContent'
import MainContentTexts from '../../components/MainContentTexts'
import PersonIcon from '../../assets/images/person-icon.png'
import HandOffIcon from '../../assets/images/hand-off.png'
import EyeIcon from '../../assets/images/eye-off.png'
import FileSyncIcon from '../../assets/images/file-sync.png'
import BrowserIcon from '../../assets/images/browser.png'
import SearchIcon from '../../assets/images/search.png'
import MonitorIcon from '../../assets/images/monitor.png'
import GlobalIcon from '../../assets/images/global.png'
import ExploringFeatures from '../../components/ExploringFeatures'
import DataSecurity from '../../components/DataSecurity'
import UserComments from '../../components/UserComments'
import FancyFooter from '../../components/FancyFooter'
import ReactGA from 'react-ga'
import Slider from "react-slick"

export default (props) => {
  let [activeSlideNumber, setActiveSlideNumber] = useState(0)
  let [navTheme, setNavTheme] = useState('dark transparent')
  let [isNavDownloadButotnVisible, setIsNavDownloadButotnVisible] = useState(false)
  let [isWheelEventFired, setIsWheelEventFired] = useState(false)
  let [newDelta, setNewDelta] = useState(null)
  let [oldDelta, setOldDelta] = useState(null)
  let [eventTimeout, setEventTimeout] = useState(null)

  const settings = {
    dots: true,
    infinite: true,
    vertical: true,
    verticalSwiping: true,
    draggable: false,
    afterChange: (currentSlide) => {
      setActiveSlideNumber(currentSlide)
      if (currentSlide === 0) {
        setNavTheme('dark transparent to-transparent')
        setIsNavDownloadButotnVisible(false)
      } else {
        setNavTheme('dark solid to-solid')
        setIsNavDownloadButotnVisible(true)
      }
    }
  }

  const sliderRef = useRef(null)

  const downloadClickEventHandleFor = (buttonId) => {
    return () => {
      ReactGA.event({
        category: 'Click',
        action: 'Download APP',
        label: `${buttonId}_from_${window.location.hostname}`
      })
    }
  }

  const onWheelEventGlobal = (e) => {

    if (isWheelEventFired) {
      return
    }
    setNewDelta(e.deltaY)
    if (oldDelta != null && oldDelta * newDelta > 0) {
      setIsWheelEventFired(true)
      clearTimeout(eventTimeout)
      setEventTimeout(setTimeout(() => { setIsWheelEventFired(false) }, 1600))
    }
    setOldDelta(newDelta)

    if (e.deltaY > 0) {
      sliderRef.current.slickNext()
    } else {
      sliderRef.current.slickPrev()
    }
  }

  return (
    <div className='page-container'>
      <div className='page-inner-container' onWheel={onWheelEventGlobal}>
        <Navigator theme={navTheme} isDownloadVisible={isNavDownloadButotnVisible} downloadClickEventHandleFor={downloadClickEventHandleFor} />
        <Slider ref={sliderRef} {...settings}>
          <HomeBanner downloadClickEventHandleFor={downloadClickEventHandleFor} />
          <MainContent
            sectionIndex={1}
            isForColoredProgress={false}
            textPart={
              <MainContentTexts
                isActive={activeSlideNumber === 1}
                featurePoint={'Photo Video Vault'}
                highlight={'Lock up your private photos and videos.'}
                items={
                  [
                    {
                      imageSrc: PersonIcon,
                      alt: 'person icon',
                      comment: 'Bind email to backup and recover your data.'
                    },
                    {
                      imageSrc: HandOffIcon,
                      alt: 'hand icon',
                      comment: 'PIN encryption safety to ensure you are the only one who has access to the app.'
                    },
                    {
                      imageSrc: EyeIcon,
                      alt: 'eye off icon',
                      comment: 'You can mark media as “Hidden” and hide them all with one tap.'
                    },
                    {
                      imageSrc: FileSyncIcon,
                      alt: 'file sync icon',
                      comment: 'Free up phone space by using our unlimited cloud.'
                    }
                  ]
                }
              />
            }
            imagePart={
              <div className='images-container pull-to-start'>
                <img src={require('../../assets/images/iphone1.png')} alt='app screenshot of iphone' className={`phone ${activeSlideNumber === 1 ? ' slideInRight' : ' disappear'}`} />
                <img src={require('../../assets/images/ipad1.png')} alt='app screenshot of ipad' className={`tablet ${activeSlideNumber === 1 ? ' slideInRight' : ' disappear'}`} />
              </div>
            }
          />
          <MainContent
            sectionIndex={2}
            isForColoredProgress={true}
            isReversed
            textPart={
              <MainContentTexts
                isActive={activeSlideNumber === 2}
                featurePoint={'Private Browser'}
                highlight={'Access a secure and personal internet environment.'}
                highlightColor={'yellow'}
                items={
                  [
                    {
                      imageSrc: BrowserIcon,
                      alt: 'browser icon',
                      comment: 'Clears browsing history when you exit the browser.'
                    },
                    {
                      imageSrc: SearchIcon,
                      alt: 'search icon',
                      comment: 'Find your search results efficiently'
                    }
                  ]
                }
              />
            }
            imagePart={
              <div className='images-container pull-to-end'>
                <img src={require('../../assets/images/ipad2.png')} alt='app screenshot of ipad' className={`tablet ${activeSlideNumber === 2 ? ' slideInLeft' : ' disappear'}`} />
                <img src={require('../../assets/images/iphone2.png')} alt='app screenshot of iphone' className={`phone ${activeSlideNumber === 2 ? ' slideInLeft' : ' disappear'}`} />
              </div>
            }
          />
          <ExploringFeatures />
          <DataSecurity />
          <UserComments />
          <FancyFooter downloadClickEventHandleFor={downloadClickEventHandleFor} />
        </Slider>
      </div>
    </div>
  )
}
