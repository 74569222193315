import React from "react";
import 'bulma/css/bulma.css';
import './EmailVerification.css';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import Logo from "../../assets/images/logo.png";

class EmailVerificationInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: '',
      message: '',
      verify_state: false
    };
  }

  componentDidMount() {
    const LANG = navigator.language;
    const api_uri = `https://noseeyou.com/api${this.props.match.url}`
    axios.get(api_uri).then((res) => {
      let { status, message } = res.data;
      if (status === "fail") {
        import("../../assets/images/email_verification_failed.png").then((EmailVerifyFailedIcon) => {
          this.setState((prevState) => {
            return {
              icon: typeof EmailVerifyFailedIcon === 'object' ? EmailVerifyFailedIcon.default : EmailVerifyFailedIcon,
              message: message,
              verify_state: true
            };
          })
        })
      } else {
        import("../../assets/images/email_verification_success.png").then((EmailVerifySuccessIcon) => {
          let msg = '';
          if (LANG === 'zh-CN') {
            msg = '邮件验证完成！'
          } else {
            msg = "Email verification completed!"
          }
          this.setState((prevState) => {
            return {
              icon: typeof EmailVerifySuccessIcon === 'object' ? EmailVerifySuccessIcon.default : EmailVerifySuccessIcon,
              message: msg,
              verify_state: true
            };
          })
        })
      }
    }).catch((err) => {
      import("../../assets/images/email_verification_failed.png").then((EmailVerifyFailedIcon) => {
        let err_msg = '';
        if (LANG === 'zh-CN') {
          err_msg = '服务器错误…… 邮件验证失败，请稍后再试……';
        } else {
          err_msg = 'Server error. Email verification failed, please try again later...';
        }
        this.setState((prevState) => {
          return {
            icon: typeof EmailVerifyFailedIcon === 'object' ? EmailVerifyFailedIcon.default : EmailVerifyFailedIcon,
            message: err_msg,
            verify_state: true
          };
        })
      })
    })
  }

  render() {
    const verify_result = (
      <div>
        <img src={this.state.icon} alt="email verification icon" />
        <div className="verify-result-text">
          { this.state.message }
        </div>
      </div>
    )

    const loader_spin = (
      <Loader
         type="Puff"
         color="#00BFFF"
         height="100"
         width="100"
      />
    )

    return (
      <div className="ev-page-container">
        <nav className="navbar" aria-label="main navigation">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <img className='nav-logo' src={Logo} alt="logo" /> <div className="site-title">Noseeyou</div>
            </a>
          </div>
        </nav>
        <div className="container container-cus">
          { this.state.verify_state === true ? verify_result : loader_spin }
        </div>
      </div>
    )
  }
}

export default EmailVerificationInfo;
