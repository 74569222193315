import React from "react";
import "./NotFound.css";
import Logo from "../../assets/images/logo.png";

class NotFound extends React.Component {
  render() {
    return (
      <div>
      <div className="nf-page-container">
        <nav className="navbar" aria-label="main navigation">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <img className='nav-logo' src={Logo} alt="logo" /> <div className="nf-site-title">Noseeyou</div>
            </a>
          </div>
        </nav>
        <div className="container nf-container-cus">
          <div className="nf-main-info">
            Page Not Found
          </div>
        </div>
      </div>
      </div>
    )
  }
}

export default NotFound;
