import React from 'react'
import './index.css'
import DownloadButton from '../DownloadButton'
import ProgressDots from '../ProgressDots'

export default (props) => {
  return (
    <div className='banner-outer'>
      <ProgressDots currentPosNumber={0} total={7} isForColored={true} />
      <div className='banner-bg-container'>
        <div className='left-box'>
          <div className='left-top-box'>
            <div className='circle small'></div>
          </div>
          <div className='left-bottom-box'>
            <div className='circle middle'></div>
          </div>
        </div>
        <div className='right-box'>
          <div className='circle big'></div>
        </div>
      </div>
      <div className='banner-infos'>
        <div className='logo-container'><img className='banner-logo' src={require('../../assets/images/logo.png')} alt='logo' /></div>
        <div className='banner-text-main'>Take control of your privacy</div>
        <div className='banner-text-more'>Photo vault, private browser and intruders.</div>
        <div className='banner-text-more last'>An all-in-one privacy vault.</div>
        <div className='download-buttons'>
          <DownloadButton className='dl-button apple' storeName={'APPLE'} onClick={props.downloadClickEventHandleFor('banner-apple')} downloadLink={`https://itunes.apple.com/us/app/noseeyou/id1186829987?mt=8`} />
          <DownloadButton className='dl-button google' storeName={'GOOGLE'} onClick={props.downloadClickEventHandleFor('banner-google')} downloadLink={``} />
        </div>
      </div>
    </div>
  )
}
