import React from "react";
import "./UniversalLink.css";
import MiddleLogo from "../../assets/images/logo.png";
import AppleIcon from "../../assets/images/apple_logo.svg";
import ReactGA from 'react-ga';

class UniversalLink extends React.Component {
  constructor(props) {
    super(props);

    this.downloadClickEventHandleFor = this.downloadClickEventHandleFor.bind(this);
  }

  downloadClickEventHandleFor(buttonId) {
    return () => {
      ReactGA.event({
        category: 'Click',
        action: 'Download APP',
        label: `${buttonId}_from_${window.location.hostname}`
      });
    }
  }

  render() {
    return (
      <div className="page-container">
        <div className="ul-banner">
          <div className="ul-main-text">
            A private place for your<br/>private things
          </div>
        </div>
        <div className="bottom-container">
          <div className="stub-box">
            <div className="center-box">
              <img className='logo-img' src={MiddleLogo} alt="logo" />
            </div>
          </div>
          <div className="download-part">
            <p className="ul-title">Noseeyou</p>
            <p className="ul-text-content">Locks down private photos, videos, files and documents.</p>
            <a className="button ul-download-button" onClick={this.downloadClickEventHandleFor('universal-link-download-button')} href="https://itunes.apple.com/us/app/noseeyou/id1186829987?mt=8">
              <img className="ul-download-icon" src={AppleIcon} alt="apple icon"/>
              <div>
                <div className="small-text">Download on the</div>
                <div className="larger-text">App Store</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default UniversalLink;
