import React from "react";
import "./Privacy.css";
import 'bulma/css/bulma.css';
import NavBar from "../../components/navigation/Navigation.js";
import Footer from "../../components/footer/Footer.js";
import Script from 'react-load-script';
import PaneImg from "../../assets/images/privacy_head_bg_image.png";
import Arrow from "../../assets/images/arrow.png";

class Privacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMoreTOSTextHidden: true,
      isMorePPTextHidden: true
    };

    this.handleTOSReadingMore = this.handleTOSReadingMore.bind(this);
    this.handlePPReadingMore = this.handlePPReadingMore.bind(this);
  }

  handleTOSReadingMore() {
    this.setState((prevState) => {
      return {isMoreTOSTextHidden: false};
    })
  }

  handlePPReadingMore() {
    this.setState((prevState) => {
      return {isMorePPTextHidden: false};
    })
  }

  render() {

    const moreTermsOfService = (
      <div>
        <p className="pt-subtitle">
          Responsibility for Content
        </p>
        <p className="pt-text-content">
          You acknowledge that all information, data, photos, videos, texts, music, sound, tags and/or other materials accessible through Noseeyou, whether publicly posted or privately transmitted, are the sole responsibility of the party from whom such content originated.
        </p>
        <p className="pt-text-content">
          This means that you, and not Noseeyou, are entirely responsible for all content that you upload, post, email, transmit or otherwise make available.
        </p>
        <p className="pt-subtitle">
          User Conduct
        </p>
        <p className="pt-text-content">
          As a condition of use, you agree not to use Noseeyou for any purpose that is prohibited by the Terms or by applicable law. You shall not (and shall not permit any third party) either (a) to take any action or (b) make available any content on or through the service that: (i) infringes or violates any patent, trademark, trade secret, copyright, contractual right, right of publicity or other right of any person or entity; (ii) violates any acceptable use or other information technology policy that may apply to your use of any computer system or network; (iii) is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another’s privacy, tortious, obscene, offensive, or profane; (iv) constitutes unauthorized or unsolicited advertising, junk or bulk email; (v) involves commercial activities and/or sales without Noseeyou’s prior written consent, such as contests, sweepstakes, barter, advertising, or pyramid schemes; (vi) impersonates any person or entity, including any employee or representative of Noseeyou; or (vii) is inappropriate in any other manner that Noseeyou determines in its sole, reasonable discretion.
        </p>
        <p className="pt-subtitle">
          Fees and Purchase Terms
        </p>
        <p className="pt-text-content">
          All fees set forth within and paid by you under the Terms is for the Noseeyou service. In no ways are these fees paid for for the license, or the use of Noseeyou application. You agree to pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. All fees for the Services are non-refundable. Noseeyou collects payments through a limited number of payment provider. Your payment provider agreement governs your use of the designated credit card or other payment method, and you must refer to that agreement and not the Terms to determine your rights and liabilities. Noseeyou reserves the right at any time to change its prices and billing methods.
        </p>
        <p className="pt-text-content">
          After your initial subscription period, and after any subsequent subscription period, your subscription will automatically commence on the first day following the end of such period and continue for an additional equivalent period, at Noseeyou’s then-current price for such subscription. If you want to cancel your subscription, you must sign into and go to the “Manage App Subscriptions” page in the Apple App Store at least 24 hours prior to the Renewal Commencement Date.
        </p>
        <p className="pt-subtitle">
          Significant Others
        </p>
        <p className="pt-text-content">
          Noseeyou cannot guarantee that your content will be safe from outside attacks, hackers or other ways of accessing your content on the file systems.
        </p>
        <p className="pt-text-content">
          You understand and agree that in no event shall Noseeyou be liable for any indirect, incidental, special, exemplary or consequential damages arising out of or in connection with the application and the service.
        </p>
        <p className="pt-text-content">
          If Noseeyou becomes aware of any possible violations by you of the Terms, Noseeyou reserves the right to investigate such violations.
        </p>
      </div>
    );

    const morePrivacyPolicy = (
      <div>
        <p className="pt-subtitle">
          Sensitive Personal Information
        </p>
        <p className="pt-text-content">
          We will never ask you to send us or submit any information related to racial or ethnic origin; political opinions or union membership; religious or other beliefs; your health, biometric or genetic information; information about your sexual preference or sexual orientation; or information about your criminal background.
        </p>
        <p className="pt-subtitle">
          Information We Collect automatically
        </p>
        <p className="pt-text-content">
          We use server logs, cookies and similar technologies to automatically collect information about your device and how you interact with Noseeyou. We may record your application version, browser type, mobile operating system, Internet Protocol address, etc.  
        </p>
        <p className="pt-subtitle">
          How We Use Your Personal Information
        </p>
        <p className="pt-text-content">
          We use your information for the following purposes:<br/>
          Provide and support the service.<br/>
          Better understand your interests and the service, make improvements to the service and to develop new features and services.<br/>
          Create anonymous data and share it with third parties to operate, understand and improve our service and for other lawful business purposes.<br/>
          Use your personal information as we believe appropriate to investigate or prevent violation of the law or our Terms of Service; to secure the service; to protect our, your or others’ rights, privacy, safety or property; and to protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.<br/>
          Use your personal information as we believe appropriate to (a) comply with applicable laws, lawful requests and legal process, and (b) to prosecute or defend legal claims.<br/>
        </p>
        <p className="pt-subtitle">
          How We Share Your Information
        </p>
        <p className="pt-text-content">
          We share your information with certain entities for the following purposes:<br/>
          Share your information with third party companies or individuals to provide services.<br/>
          Disclose information about you as we believe appropriate to government or law enforcement officials or private parties for compliance with law, legal claims, security, fraud prevention and safety.<br/>
          Sell, transfer or otherwise share some or all of our business or assets, including your personal information, in connection with a business deal, such as a merger, consolidation, acquisition, reorganization, sale of assets or in the event of bankruptcy.<br/>
        </p>
        <p className="pt-subtitle">
          Security
        </p>
        <p className="pt-text-content">
          We use a variety of industry-standard technologies and procedures to help protect your personal information from unauthorized access, use, or disclosure. However, security risk is inherent in all information technology. Thus, while we use reasonable efforts to protect your personal information, we cannot guarantee its absolute security.
        </p>
      </div>
    )

    const lastOfTOSElementBefore = (
      <div className="pt-text-content-last">
        <p className="pt-text-content">
          In order to access certain features of Noseeyou, you may be required to become a registered user and be advised to bind the account to an email address. In registering for the service, you agree to provide true, accurate, current and complete information about yourself. You represent that you are (1) of legal age to form a binding contract; and (2) not a person barred from using the Service under the laws of the United States.
        </p>
        <p className="pt-text-content">
          You agree not to create an account using a false identity or information, or on behalf of someone other than yourself. Noseeyou reserves the right to remove or reclaim any usernames at any time and for any reason, including but not limited to, claims by a third party that a username violates the third party’s rights.
        </p>
      </div>
    )

    const lastOfTOSElementAfter = (
      <div>
        <p className="pt-text-content">
          In order to access certain features of Noseeyou, you may be required to become a registered user and be advised to bind the account to an email address. In registering for the service, you agree to provide true, accurate, current and complete information about yourself. You represent that you are (1) of legal age to form a binding contract; and (2) not a person barred from using the Service under the laws of the United States.
        </p>
        <p className="pt-text-content">
          You agree not to create an account using a false identity or information, or on behalf of someone other than yourself. Noseeyou reserves the right to remove or reclaim any usernames at any time and for any reason, including but not limited to, claims by a third party that a username violates the third party’s rights.
        </p>
      </div>
    )

    const lastOfPPElementBefore = (
      <div className="pt-text-content-last">
        <p className="pt-text-content">
          We collect your personal information when you create an account, such as your email address and password. When you use our service, you can store different types of data in the service, such as photos, videos, files and other content. When you order paid versions of the service we collect information necessary to complete the transaction, including your name, payment information and billing information. You may share information with us when you request customer support or otherwise interact with us. If you decide to invite someone else to create an account on Noseeyou, we will collect your and the third party’s names and email addresses. You may choose to give us permission to access certain data on or components of your device, such as your photos, contacts, microphone and camera. You can always disable this access in your device’s settings.
        </p>
      </div>
    )

    const lastOfPPElementAfter = (
      <div>
        <p className="pt-text-content">
          We collect your personal information when you create an account, such as your email address and password. When you use our service, you can store different types of data in the service, such as photos, videos, files and other content. When you order paid versions of the service we collect information necessary to complete the transaction, including your name, payment information and billing information. You may share information with us when you request customer support or otherwise interact with us. If you decide to invite someone else to create an account on Noseeyou, we will collect your and the third party’s names and email addresses. You may choose to give us permission to access certain data on or components of your device, such as your photos, contacts, microphone and camera. You can always disable this access in your device’s settings.
        </p>
      </div>
    )

    return (
      <div>
        <NavBar/>
        <div className="hero main-content">
          <img className="content-banner" src={PaneImg} alt="content background"/>
          <div className="left-top-triangle"></div>
          <div className="right-triangle"></div>
          <div className="pt-bg-cover"></div>
          <div className="banner-info">
            <div className="main-info">Noseeyou<br/>Guards your privacy.</div>
            <div className="sub-info">We’re committed to building a place where no one sees you. </div>
          </div>
        </div>
        <section className="section second-content">
          <div className="container">
            <p className="principles-title second-text">We’re guided by the following four principles about your privacy rights.</p>
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                <div className="tile is-child">
                  <p className="principles-subtitle second-text">1. We don’t look in your Noseeyou.</p>
                  <p className="principles-detail second-text">
                    Noseeyou is striving to build a place where only you can see the content within. We will never look at any content in your Noseeyou account.
                  </p>
                </div>
              </div>
              <div className="tile is-parent">
                <div className="tile is-child">
                  <p className="principles-subtitle second-text">2. We collect your data to improve our service.</p>
                  <p className="principles-detail second-text">
                    We only collect your data to better understand your interests and the service, make improvements to the service and to develop new features and services.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                <div className="tile is-child">
                  <p className="principles-subtitle second-text">3. We protect your personal information.</p>
                  <p className="principles-detail second-text">
                    We use a variety of industry-standard technologies and procedures to help protect your personal information from unauthorized access, use, or disclosure.
                  </p>
                </div>
              </div>
              <div className="tile is-parent">
                <div className="tile is-child">
                  <p className="principles-subtitle second-text">4. We only reveal your data for legal or safety issues. </p>
                  <p className="principles-detail second-text">
                    We only reveal view or share your personal data only if we’re required by law or if we have concerns about potential violations to our Terms of Service or Privacy Policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section pt-section pt-section-first">
          <div className="fake-bg"></div>
          <div className="section-like-container">
            <div className="container pt-container">
              <p className="pt-title">
                <img src={Arrow} alt="arrow icon"/>
                Noseeyou
              </p>
              <p className="pt-text-content">
                Noseeyou is a professional privacy application that aims to provide a secure digital environment and a completely private space for all users. You can hides all your personal data, such as photos, videos, notes and even the intention of hiding itself in Noseeyou. Noseeyou also allows you to browse websites without being discovered by curious eyes. Noseeyou puts your data privacy first and is constantly working on more features, products and services to improve your experience.
              </p>
            </div>
          </div>
        </section>
        <section className="section pt-section">
          <div className="container pt-container">
            <p className="pt-title">
              <img src={Arrow} alt="arrow icon"/>
              Terms of Services
            </p>
            <p className="pt-text-content">
              Please read this terms of service agreements (the “Terms”) carefully. By accessing or using this application in any way, you represent that you have read, understand and agree to be bound by the Terms.
            </p>
            <p className="pt-text-content">
              Please note that the Terms are subject to change by Noseeyou in its sole discretion at any time. When changes are made, Noseeyou will update the Terms. Any changes will be effective immediately for new users of Noseeyou and will be effective thirty (30) days after posting of such changes for existing users.
            </p>
            <p className="pt-text-content">
              Please regularly check the Terms to view the then-current agreement.
            </p>
            <p className="pt-subtitle">
              Registration
            </p>
            {this.state.isMoreTOSTextHidden ? lastOfTOSElementBefore : lastOfTOSElementAfter}
            {this.state.isMoreTOSTextHidden ? <button onClick={this.handleTOSReadingMore} className="continue-reading"><div className="continue-arrow"></div>Continue Reading</button> : moreTermsOfService}
          </div>
        </section>
        <section className="section pt-section">
          <div className="container pt-container">
            <p className="pt-title">
              <img src={Arrow} alt="arrow icon"/>
              Privacy Policy
            </p>
            <p className="pt-text-content">
              This Privacy Policy describes how Noseeyou handles personal information we collect about users at our mobile application.
            </p>
            <p className="pt-text-content">
              We may modify this Privacy Policy at any time. Any modifications to this Privacy Policy will be effective when posted (or as otherwise indicated at the time of posting). Continued use of Noseeyou after an update to this Privacy Policy will indicate your acceptance of the update.
            </p>
            <p className="pt-text-content">
              Please regularly check the Privacy Policy to view the then-current policy.
            </p>
            <p className="pt-subtitle">
              We collect your personal information like your email address when you create an account.
            </p>
            {this.state.isMorePPTextHidden ? lastOfPPElementBefore : lastOfPPElementAfter}
            {this.state.isMorePPTextHidden ? <button onClick={this.handlePPReadingMore} className="continue-reading"><div className="continue-arrow"></div>Continue Reading</button> : morePrivacyPolicy}
          </div>
        </section>
        <Footer/>
        <Script
          attributes={{ id: "ze-snippet"}}
          url="https://static.zdassets.com/ekr/snippet.js?key=b8906543-79e1-40a9-879c-d296c54af582"
        />
      </div>
    )
  }
}

export default Privacy;
