import React from 'react';
import './index.css';
import DownloadButton from '../DownloadButton';

export default (props) => {

  return (
    <div className='ff-outer'>
      <div className='ff-container'>
        <div className='real-logo-container'>
          <div className='cutter transitionfix'>
            <div className='in-cutter'>
              <img className='ff-logo-bg' src={require('../../assets/images/fancy-logo-bg.png')} alt='logo' />
            </div>
          </div>
          <div className='ff-logo-eye'>
            <img style={{width: '100%'}} src={require('../../assets/images/fancy-logo-eye.png')} alt='pink glasses' />
          </div>
        </div>

        <img className='ff-new-logo' src={require('../../assets/images/logo_new.png')} alt='noseeyou logo' />
        <div className='ff-text-main'>Take control of your privacy.</div>
        <div className='download-buttons'>
          <DownloadButton className='dl-button apple' storeName={'APPLE'} onClick={props.downloadClickEventHandleFor('footer-apple')} downloadLink={`https://itunes.apple.com/us/app/noseeyou/id1186829987?mt=8`} />
          <DownloadButton className='dl-button google' storeName={'GOOGLE'} onClick={props.downloadClickEventHandleFor('footer-google')} downloadLink={``} />
        </div>
      </div>
    </div>
  )
}
