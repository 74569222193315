import React from "react";
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import EmailVerificationInfo from "./pages/email_verification/EmailVerification.js";
import HomeNew from './pages/HomeNew';
import Privacy from "./pages/privacy/Privacy.js";
import NotFound from "./pages/not_found/NotFound.js";
import UniversalLink from "./pages/universal_link/UniversalLink.js";
import ReactGA from 'react-ga';
import withTracker from './withTracker.js';

class AppMain extends React.Component {
  componentWillMount() {
    ReactGA.initialize('UA-129606554-1')
    ReactGA.pageview('/');
  }

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/account/email_verify/:userEmailModelId/:code" component={withTracker(EmailVerificationInfo)} />
            <Route exact path="/" component={withTracker(HomeNew)} />
            <Route path="/privacy" component={withTracker(Privacy)} />
            <Route path="/privacy.html" component={withTracker(Privacy)} />
            <Route path="/universal-links/private-share/:token" component={withTracker(UniversalLink)}/>
            <Route path="/universal-links/add-chat-friends/:token/:friendInviteId" component={withTracker(UniversalLink)}/>
            <Route path="/universal-links/login/:token" component={withTracker(UniversalLink)}/>
            <Route component={withTracker(NotFound)}/>
          </Switch>
        </div>
      </Router>
    )
  }
}

export default AppMain;
