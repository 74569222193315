import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './index.css';
import 'bulma/css/bulma.css';
import Logo from '../../assets/images/logo_new.png';
import DownloadButton from '../DownloadButton';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default (props) => {
  let { theme } = props;
  let [ appVersion, setAppVersion ] = useState(null);
  let [ isAppVersionFetched, setIsAppVersionFetched ] = useState(false)

  useEffect(() => {
    axios.get('https://noseeyou.com/api/app')
      .then(function (response) {
        // handle success
        setAppVersion(response.data.app.version);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
        setIsAppVersionFetched(true)
      });
  }, [isAppVersionFetched])

  return (
    <nav className={`navbar ${theme ? theme : ''}`} aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <div className={`navigator-site-title ${theme ? theme : ''}`}>
            <img src={Logo} alt='noseeyou logo' />
            <div className='version-container'>
              {!appVersion ? <FontAwesomeIcon className='app-version-spinner' icon={faSpinner} /> :  <span className='suffix-version'>{appVersion}</span> }
            </div>
          </div>
        </Link>
      </div>
      <div className={`top-dl-buttons ${props.isDownloadVisible ? 'visible' : 'hidden'}`}>
        <DownloadButton isMini className='top-dl-button apple' storeName={'APPLE'} onClick={props.downloadClickEventHandleFor('nav-apple')} downloadLink={`https://itunes.apple.com/us/app/noseeyou/id1186829987?mt=8`} />
        <DownloadButton isMini className='top-dl-button google' storeName={'GOOGLE'} onClick={props.downloadClickEventHandleFor('nav-google')} downloadLink={``} />
      </div>
    </nav>
  )
}
