import React, { useState, useEffect } from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faStar, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import Moment from 'react-moment';
import useInterval from '../useInterval';

export default (props) => {
  let [ fadingClass, setFadingClass ] = useState('comment-fade-in');
  let [ commentData, setCommentData ] = useState({});

  useEffect(() => {
    if (fadingClass === 'comment-fade-in') {
      setCommentData(JSON.parse(JSON.stringify(props.data)))
    }
  }, [ fadingClass, props.data ])

  useInterval(() => {
    handleClick('NEXT')()
  }, 5000);

  const buildCommentStars = (numberOfStars) => {
    var stars = [], index = 0;
    numberOfStars = parseInt(numberOfStars)
    while (numberOfStars > 0) {
      stars.push(
        <FontAwesomeIcon
          key={ index }
          icon={ faStar }
          color="#FFCA28"
        />
      )
      index += 1;
      numberOfStars -= 1;
    }
    while (stars.length < 5) {
      stars.push(
        <FontAwesomeIcon
          key={ index }
          icon={ faStarEmpty }
          color="#FFCA28"
        />
      )
      index += 1;
    }
    return stars;
  }

  const handleClick = (type) => {
    return () => {
      setFadingClass('comment-fade-out')
      setTimeout(_ => {
        setFadingClass('comment-fade-in')
      }, 400)
      if (type === 'PREV') {
        props.handlePrev(props.activeIndex)
      } else {
        props.handleNext(props.activeIndex)
      }
    }
  }

  return (
    <div className='comment-container'>
      <div className='left-arrow' onClick={ handleClick('PREV') }>
        <FontAwesomeIcon
          className='switch-icon'
          icon={ faChevronLeft }
          color="#111"
        />
      </div>
      {
        commentData ?
        <div className={`comment-details ${fadingClass}`}>
          <div className='head'>
            <div className='comment-title'>{ commentData.title }</div>
            <div className='comment-score'>
              { buildCommentStars(commentData.rating) }
            </div>
          </div>
          <div className='comment-detail'>
            { commentData.content }
          </div>
          <div className='comment-other-info'>
            <div className='user-nickname'>{ commentData.author }</div>
            <div>|</div>
              <Moment format="YYYY/MM/DD">
                { commentData.updated_at }
              </Moment>
          </div>
        </div> :
        <FontAwesomeIcon className='comment-spinner' size='5x' icon={faSpinner} />
      }
      <div className='rigth-arrow' onClick={ handleClick('NEXT') }>
        <FontAwesomeIcon
          className='switch-icon'
          icon={ faChevronRight }
          color="#111"
          />
      </div>
    </div>
  )
}
